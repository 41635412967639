import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import {
  Button,
  Form,
  FormItem,
  Input,
  MessageBox,
  Message,
  Image,
  Carousel,
  CarouselItem,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './common/pub_css.css';  // 全局公共样式


Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.$message = Message;
Vue.prototype.$messagebox = MessageBox;

Vue.prototype.$imgSrc = 'https://minio.zthyoa.com/ss-web/';


Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Image)
Vue.use(Carousel);
Vue.use(CarouselItem);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  // console.log(to, from)
  next()
})

router.afterEach((to, from, next) => {
  // console.log(to, from)

  if (to.meta.title) {
    document.title = to.meta.title
  }

  // if (from.name == 'jobList' || from.name== 'wapJobList') {
  //   return;
  // }

  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});
