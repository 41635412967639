<template>
  <div id="app">
    <headerView></headerView>

    <router-view/>

    <footerView></footerView>
    
  </div>
</template>


<script>

  import headerView from '@/components/pc/headerView.vue'
  import footerView from '@/components/pc/footerView.vue'
 
  export default{
    components: {
      headerView,
      footerView
    },  
    data(){
      return{

      }
    }
  }
</script>

<style>
  body{
    margin: 0;
  }
  div{
    line-height: 1;
  }
</style>
