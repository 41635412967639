<template>
  <div style="background-color: #F2F2F2;">
     <!-- height: 1080px; -->
     <el-image :src="$imgSrc+ 'homebg.jpg'" fit="cover" style="width: 100%; height: calc(100vh - 80px); min-width: 1200px;"></el-image>

     <!-- <el-carousel trigger="click" style="min-width: 1200px;">
          <el-carousel-item v-for="item in 4" :key="item">
               <el-image :src="$imgSrc+ 'homebg.jpg'" fit="cover" style="width: 100%; height: 1000px;"></el-image>
               <div class="banner_po dis_column">
                    <div class="c_fff bold tip l4" style="font-size: 36px; letter-spacing: 10px;">七炭河烤串</div>
                    <div class="h_line"></div>
                    <div class="f_22 c_fff tip l4" style="letter-spacing: 10px;">匠心·制作</div>
                    <div class="f_22 c_fff tip l4" style="letter-spacing: 10px; margin-top: 20px;">精心·选材</div>
               </div>
          </el-carousel-item>
     </el-carousel> -->


     <div class="con_b" style="padding: 110px 0 160px;">
          <div class="dis_column" style="width: 100%; height: 100px;">
               <div class="f_24 bold l4">企业简介</div>
               <div class="f_13" style="margin-top: 16px;">Company Profile</div>
          </div>

          <div class="dis_between" style="align-items: flex-start; width: 1200px; min-width: 1200px; margin: 50px auto 0;">
               <div style="width: 648px;">
                    <div class="f_15 l2" style="line-height: 3;">食锁餐饮管理有限公司，是一家致力于餐饮品牌孵化、产品研发、技术培训和服务推广的综合性服务平台。在餐饮市场和消费形态变革的趋势下，我们秉持以消费者需求为核心的战略理念，不断升级，精心打造符合当代审美的时尚用餐环境。并且通过精致的菜品、优质的服务和独特的用餐氛围为顾客提供美妙的用餐体验。
                    <br>目前食锁专注于中式特色餐饮，致力于中华特色餐饮的新品开发，科学改良及中餐文化的传播，秉持“以一流的品质，创一流的品牌”的态度，希望造就出更新、更强、更具民族特色的餐饮品牌企业。为更好的弘扬民族饮食文化推波助澜，食锁一直把食品安全作力前要原则，注看研发和品质管理， 不断进行技术创新。从产品到原料采购，品控化储、物流到出品时同都产格实行数字化管理的强大供应钱，为确保每家门店的产品都始终如一地保持卓越品质。</div>
                    <div class="more dis_center" style="background-color: #0071E3; color: #fff; margin-top: 70px; cursor: pointer;">MORE</div>
               </div>
               <div style="width: 510px; position: relative;">
                    <el-image :src="$imgSrc+ 'h3.jpg'" fit="cover" style="width: 400px; height: 600px;"></el-image>
                    <div style="position: absolute; right: 0; top: 74px;">
                         <div class="bold l3" style="font-size: 40px; letter-spacing: 20px;">SINCE</div>
                         <div class="f_28 bold" style="margin-top: 16px; text-align: right; padding-right: 10px; box-sizing: border-box; letter-spacing: 6px;">2023</div>
                    </div>
               </div>
          </div>
     </div>

     <div class="con_b" style="padding-bottom: 88px;">
          <div class="dis_column" style="width: 100%; height: 280px;">
               <div class="f_24 bold l4">特色食品</div>
               <div class="f_13" style="margin-top: 16px;">Specialty foods</div>
          </div>

          <div class="dis_between" style=" align-items: flex-start;">
               <div style="width: 690px;">
                    <div class="dis_between" style="width: 690px; align-items: flex-start;">
                         <div class="dis_column">
                              <div class="le dis_column">
                                   <div class="bo1"></div>
                                   <div class="lecon">
                                        <div class="hl"></div>
                                        <div class="l2 bold f_24" style="color: #BABABA;">Cold Red-Stewed</div>
                                        <div class="l2 bold f_24" style="color: #BABABA; margin-top: 18px;">Duck Series</div>
                                        <div class="bold f_24" style="color: #8B6631; margin-top: 40px;">冷卤鸭货系列</div>
                                   </div>
                                   <div class="bo2"></div>
                              </div>
                              <div class="dis_center" style="margin-top: 40px;">
                                   <div class="more dis_center f_13" style="margin-right: 10px;">更多美食 +</div>
                              </div>
                         </div>
                         <div class="dis_column" style="align-items: flex-end; padding-top: 40px; box-sizing: border-box;">
                              <div class="hl" style="transform: translateX(-10px);"></div>
                              <div class="f_18" style="color: #8B6631; letter-spacing: 8px; margin: 10px 0 10px;">匠·心·制·作</div>
                              <div class="f_18" style="color: #8B6631; letter-spacing: 8px; margin-bottom: 30px;">精·心·选·材</div>
                              <div class="dis" style="flex-wrap: wrap; align-items: flex-start; justify-content: space-between; width: 194px;">
                                   <div class="boxx dis_center f_11">甜辣鸭锁骨</div>
                                   <div class="boxx dis_center f_11">冷卤鲍鱼</div>
                                   <div class="boxx dis_center f_11">冷卤时蔬</div>
                                   <div class="boxx dis_center f_11">冷卤鸭爪</div>
                              </div>
                         </div>
                    </div>
                    <div class="dis" style="width: 660px; height: 206px;margin-top: 90px;">
                         <el-image :src="$imgSrc+ 'h1.jpg'" style="width: 138px; height: 100%;" fit="cover"></el-image>
                         <div class="h1div">
                              <div class="f_18">七台河5分熟</div>
                              <div class="f_15" style="color: #989898; margin: 10px 0 20px;">焦香鲜嫩</div>
                              <div class="f_12" style="color: #989898; line-height: 1.5;">草原牛肉才够鲜。呼伦贝尔大草原，为七炭河烧烤食材溯源地提供高质量保证，每一份肉源，均来自草原放养品质牛肉。</div>
                         </div>
                    </div>
               </div>

               <div class="lr">
                    <div style="width: 100%; height: 100%; position: relative;">
                         <el-image :src="$imgSrc+ 'h1.jpg'" style="width: 100%; height: 100%;"></el-image>
                         <div class="lr_b dis_column" style="align-items: flex-start;">
                              <div class="f_22 c_fff l2" style="margin-bottom: 13px;">七台河5分熟</div>
                              <div class="f_14" style="color: #C7C7C7;">精选呼伦贝尔大草原牛肉</div>
                         </div>
                    </div>
               </div>

          </div>
     </div>


     <div class="con_b" style="padding: 186px 0 88px; background-color: #fff;">

          <div class="dis_between" style="width: 1200px; margin: 0 auto; align-items: flex-start;">
               <div class="lr">
                    <div style="width: 100%; height: 100%; position: relative;">
                         <el-image :src="$imgSrc+ 'h2.jpg'" style="width: 100%; height: 100%;"></el-image>
                         <div class="lr_b dis_column" style="align-items: flex-start;">
                              <div class="f_22 c_fff l2" style="margin-bottom: 13px;">特色鸭头</div>
                              <div class="f_14" style="color: #C7C7C7;">百分百当日现卤</div>
                         </div>
                    </div>
               </div>

               <div style="width: 690px;">
                    <div class="dis_between" style="width: 690px; align-items: flex-start;">
                         <div class="dis_column">
                              <div class="le dis_column">
                                   <div class="bo1"></div>
                                   <div class="lecon">
                                        <div class="hl"></div>
                                        <div class="l2 bold f_24" style="color: #BABABA;">Qitaihe</div>
                                        <div class="l2 bold f_24" style="color: #BABABA; margin-top: 18px;">Barbecue Series</div>
                                        <div class="bold f_24" style="color: #8B6631; margin-top: 40px;">七台河烧烤系列</div>
                                   </div>
                                   <div class="bo2"></div>
                              </div>
                              <div class="dis_center" style="margin-top: 40px;">
                                   <div class="more dis_center f_13" style="margin-right: 10px;">更多美食 +</div>
                                   <div class="more dis_center f_13" style="background-color: #0071E3; color: #fff;">更多美食 +</div>
                              </div>
                         </div>
                         <div class="dis_column" style="align-items: flex-end; padding-top: 40px; box-sizing: border-box;">
                              <div class="hl" style="transform: translateX(-10px);"></div>
                              <div class="f_18" style="color: #8B6631; letter-spacing: 8px; margin: 10px 0 10px;">匠·心·制·作</div>
                              <div class="f_18" style="color: #8B6631; letter-spacing: 8px; margin-bottom: 30px;">精·心·选·材</div>
                              <div class="dis" style="flex-wrap: wrap; align-items: flex-start; justify-content: space-between; width: 194px;">
                                   <div class="boxx dis_center f_11">七台河牛肉串</div>
                                   <div class="boxx dis_center f_11">七台河牛生筋</div>
                                   <div class="boxx dis_center f_11">七台河羊肉串</div>
                                   <div class="boxx dis_center f_11">七台河猪肥瘦</div>
                              </div>
                         </div>
                    </div>
                    
                    <div class="dis" style="width: 660px; height: 206px;margin-top: 90px;">
                         <el-image :src="$imgSrc+ 'h2.jpg'" style="width: 138px; height: 100%;" fit="cover"></el-image>
                         <div class="h1div">
                              <div class="f_18">特色鸭头</div>
                              <div class="f_15" style="color: #989898; margin: 10px 0 20px;">卤味十足</div>
                              <div class="f_12" style="color: #989898; line-height: 1.5;">放肆热辣，精选四省辣椒，吃着放心，百分百当日现卤，慢火熬制卤汁，八十度慢火熬制，古法秘制配方，三十多味中草药。</div>
                         </div>
                    </div>
               </div>

               

          </div>
     </div>

     <!-- <div class="con_b" style="padding: 186px 0 88px;"></div> -->

     


     <div class="con_b dis" style="position: relative; padding: 0; justify-content: flex-end; background-color: #fff;">
          <el-image :src="$imgSrc+ 'h4.jpg'" style="width: 626px; height: 1080px;" fit="cover"></el-image>
          <div class="btt_tilt f_28 bold l4 dis">
               Product advant
               <div style="color: #fff;">ages</div>
          </div>
          <div style="padding: 186px 0 88px; box-sizing: border-box; position: absolute; left: calc((100% - 1200px) / 2); top: 0; min-width: 1200px;">
               <div class="f_18 bold l2" style="margin-bottom: 80px;">“炭火燃情，美味共舞—七炭河，用心打造烧烤的艺术。”</div>
               <div style="width:  calc((100% - 626px);backdrop-filter: blur(5px);">
                    <div class="btt_con"><span style="color: #0071E3;">好食材：</span>我们与当地农户建立了深厚的合作关系，精选来自东北大地的优质肉类和新鲜蔬菜，确保每一位顾客品尝到的都是新鲜、美味的食材。</div>
                    <div class="btt_con"><span style="color: #0071E3;">好工艺：</span>烧烤大师们拥有丰富的烹饪经验，传承着东北传统的烧烤技艺，精湛的操作使每一块食材都能达到火候的完美平衡，保留原汁原味。</div>
                    <div class="btt_con"><span style="color: #0071E3;">好选品：</span>不仅提供传统的烧烤美味，还结合东北特色菜品，如锅包肉、东北大拉皮等，让顾客在“七炭河”感受到更多元化的东北风味。</div>
                    <div class="btt_con"><span style="color: #0071E3;">好文化：</span>“七炭河”不仅仅是一个美食场所，更是一个东北文化的代言人。通过音乐、装饰和服务，将东北烧烤文化融入品牌，使顾客在用餐的同时，感受到浓厚的东北风情。</div>
                    <div class="btt_con"><span style="color: #0071E3;">好创新：</span>我们致力于不断创新菜单推出季节性特色菜品，以及独特的调味，让顾客在每一次光临时都能有新的惊喜。</div>
                    <div class="btt_con"><span style="color: #0071E3;">好服务：</span>我们注重顾客的用餐体验，提出周到的服务，确保每一位顾客都能在“七炭河”享受到独特而愉悦的用餐时光</div>
               </div>
          </div>
     </div>



  </div>
</template>

<script>
export default {
     
}
</script>

<style scoped>
     /deep/ .el-carousel__container{
          height: 1000px;
     }
     .banner_po{
          position: absolute;
          top: 210px;
          right: calc(50% - 450px);
          width: 1200px;
          align-items: flex-end;
     }
     .h_line{
          width: 100px;
	     height: 4px;
          background-color: #E38800;
          margin: 20px 0 50px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 1);
     }
     .tip {
          text-shadow: 0 2px 8px rgba(0, 0, 0, 1);
     }


     /* swiper  轮播指示点 */
   /deep/ .el-carousel__indicator--horizontal{
     padding: 8px 4px;
   }
   /deep/ .el-carousel__button{
     width: 16px;
     height: 16px;
     border-radius: 50%;
     opacity: 1;
   }
   /deep/ .is-active .el-carousel__button{
     background-color: #0071E3 ;
     width: 40px;
     border: 1px solid #fff;
     border-radius: 16px;
     transform: translateY(-1px);
   }

   .con_b{
     width: 100%;
     padding: 0 calc((100% - 1200px) / 2);
     box-sizing: border-box;
     min-width: 1200px;
   }
   .le{
     border-left: 1px solid #c0c0c0;
     width: 456px;
     height: 374px;
     align-items: flex-start;
     justify-content: space-between;
   }
   .bo1{
     width: 250px;
	height: 74px;
     border-top: 1px solid #c0c0c0;
     border-right: 1px solid #c0c0c0;
     box-sizing: border-box;
   }
   .bo2{
     width: 250px;
	height: 74px;
     border-bottom: 1px solid #c0c0c0;
     border-right: 1px solid #c0c0c0;
     box-sizing: border-box;
   }
   .lecon{
     padding-left: 140px;
     box-sizing: border-box;
   }
   .hl{
     width: 45px;
	height: 4px;
     border-radius: 4px;
     background-color: #BABABA;
     margin-bottom: 10px;
   }
   .more{
     width: 112px;
	height: 34px;
     box-sizing: border-box;
     border: 1px solid #0071E3;
     color: #0071E3;
   }
   .boxx{
     width: 88px;
	height: 32px;
     box-sizing: border-box;
     border: 1px solid #989898;
     color: #595959;
     margin-bottom: 18px;
   }
   .h1div{
     width: calc(100% - 138px);
	height: 100%;
     border: 1px solid #BABABA;
     border-left: 0;
     box-sizing: border-box;
     padding: 30px 20px 0 40px;
   }
   .lr{
     width: 476px;
	height: 708px;
     box-sizing: border-box;
     border: 1px solid #BEBEBE;
     padding: 20px 16px;
   }
   .lr_b{
     width: 100%;
	height: 130px;
     background-color: #000;
     position: absolute;
     left: 0;
     bottom: 0;
     padding-left: 40px;
     box-sizing: border-box;
   }
   .btt_tilt{
     text-transform: uppercase;
     position: absolute;
     right: 534px;
     top: 120px;
     z-index: 10;
   }
   .btt_con{
     width: 100%;
     font-size: 15px;
     line-height: 2.6;
     letter-spacing: 4px;
     /* backdrop-filter: blur(5px);  */
   }
</style>